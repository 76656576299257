<template>
  <div class="shop-dashboard-info">
    <div class="wrap-accepted-orders">
      <div v-if="accpetedList && accpetedList.length" class="wrap-request-infos mb-2">
        <div class="grid-view d-flex mt-0">
          <div v-for="(props, index) of accpetedList" :key="index" class="wrap-request-items">
            <dashboard-cards :payload="props" @view-item="viewServiceHistory" @update-request="updateRequestApproval" />
          </div>
        </div>
      </div>
      <b-card class="w-100">
        <b-row class="btn-wrapper-create-order-dashboard">
          <div class="col-12">
            <div class="d-md-flex justify-content-between px-md-2 mb-3">
              <div>
                <div class="row d-md-flex mb-2 mb-md-0 justify-content-md-start align-content-center align-items-center d-sm-flex justify-content-sm-between">
                  <div class="col-12 col-md-auto">
                    <b-input-group>
                      <template #append>
                        <div
                          class="d-flex align-items-center"
                          @click="copyUrl"
                          style="border: 1px solid #d8d6de; border-top-right-radius: 6px; border-bottom-right-radius: 6px; border-left: 2px solid #d8d6de"
                        >
                          <b-img style="padding: 5px" width="35" height="35" :src="require('@/assets/images/app-icons/copy_new.png')" />
                        </div>
                      </template>
                      <b-form-input :value="storeUrl" class="fs-p875rem" readonly />
                    </b-input-group>
                  </div>
                  <div class="col-auto pr-sm-0 mt-1 mt-md-0">
                    <b-button block-sm variant="light" size="sm" class="border-dark" :href="storeUrl" target="_blank">
                      <div class="d-flex justify-content-between align-items-center">
                        <div class="pr-md-1"><img :src="`${$apiUrl}/images/icons/visit.svg`" class="store-profile-3-icons" /></div>
                        <div class="fs-p875rem">{{ $t('VISIT') }}</div>
                      </div>
                    </b-button>
                  </div>
                  <div class="col-auto pr-sm-0 mt-1 mt-md-0">
                    <b-button variant="light" size="sm" class="border-dark" @click="$superApp.actions.sharePage('MOM', '', storeUrl)">
                      <div class="d-flex justify-content-between align-items-center">
                        <div class="pr-md-1"><img :src="`${$apiUrl}/images/icons/share.svg`" class="store-profile-3-icons" /></div>
                        <div class="fs-p875rem">{{ $t('SHARE') }}</div>
                      </div>
                    </b-button>
                  </div>
                  <div class="col-auto pr-sm-0 mt-1 mt-md-0">
                    <b-button variant="light" size="sm" class="border-dark" @click="generateQr">
                      <div class="d-flex justify-content-between align-items-center">
                        <div class="pr-md-1"><img :src="`${$apiUrl}/images/icons/qr-code.svg`" class="store-profile-3-icons" /></div>
                        <div class="fs-p875rem">{{ $t('QR CODE') }}</div>
                      </div>
                    </b-button>
                  </div>
                </div>
              </div>
              <div>
                <div class="row d-md-flex justify-content-end">
                  <div class="col-12 col-md-auto">
                    <b-input-group class="input-group-merge">
                      <b-input-group-append style="border: 1px solid #d8d6de; border-top-left-radius: 6px; border-bottom-left-radius: 6px; border-right: 2px solid #d8d6de">
                        <b-img width="35" height="35" style="padding: 5px" :src="`${$apiUrl}/images/icons/filter.svg`" />
                      </b-input-group-append>
                      <select class="form-control pl-1" @change="getFiltered">
                        <option value="">All status</option>
                        <option v-for="(item, index) of requestStatusList.filter(x => x.value)" :key="index" :value="item.value">
                          {{ item.label }}
                        </option>
                      </select>
                    </b-input-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <b-col md="12">
            <good-data-table
              ref="myRequestsGrid"
              :columns="accpetedOrdersColumns"
              :no-serial="true"
              :total-column="'response.responseData.total'"
              :data-column="'response.responseData.data'"
              :api-endpoint="'/shop/dispatcher/accepted_orders'"
              :button-label="$t('Add Order')"
              :searchPlaceholder="`Search by Order ID`"
              @add-new-info="createOrderModal"
            />
          </b-col>
        </b-row>
      </b-card>
    </div>
    <b-modal v-model="showCancelOrderModel" :title="$t('Cancel Order')" :no-close-on-backdrop="true" @hidden="showCancelOrderModel = false" @ok="cancelStoreOrder">
      <b-col md="12">
        <b-form-group :label="$t('Cancel Reason')">
          <b-form-input v-model="storeCancelReason" type="text" />
        </b-form-group>
      </b-col>
    </b-modal>
    <b-modal
      id="updateRequestModal"
      v-model="serviceHistoryProps.viewUpdateRequestModal"
      :no-close-on-backdrop="true"
      :title="$t('Update Request')"
      @hidden="serviceHistoryProps.viewUpdateRequestModal = false"
      @ok="updateRequestStatus"
    >
      <div class="wrap-update-request">
        <div class="col-md-12">
          <b-form-group :label="$t('Status')">
            <v-select
              v-model="serviceHistoryProps.updateRequest.selectedStatus"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="isEmpty(serviceHistoryProps.currentServiceForDispute.digital_files) ? requestStatusList : digitalOrderStatusList"
              :reduce="val => val.value"
              :clearable="false"
            />
          </b-form-group>
          <b-form-group v-if="['ASSIGNED', 'PICKEDUP', 'COMPLETED'].includes(serviceHistoryProps.updateRequest.selectedStatus)" :label="$t('Agents')">
            <v-select
              v-model="serviceHistoryProps.updateRequest.assignedAgent"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="agentListProvider"
              :reduce="val => val.value"
              :clearable="false"
            />
          </b-form-group>
        </div>
      </div>
    </b-modal>
    <b-modal id="createOrderModal" v-model="showCreateNewOrder" hide-footer :no-close-on-backdrop="true" :title="$t('Create Order')" size="xl" @hidden="showCreateNewOrder = false" style="background-color: #f2f2f2;">
      <order-creation ref="orderForm" @create-order="orderCreated" />
    </b-modal>
    <b-modal id="assignOrderToAgentModal" v-model="showAssignOrderToAgent" hide-footer :no-close-on-backdrop="true" :title="$t('Assign Order')" size="xl" @hidden="showAssignOrderToAgent = false">
      <find-agents :order-id="activeAssignOrder" @show-on-map="showOnMap" />
    </b-modal>
    <b-modal v-model="showAgentOnMap" hide-footer :no-close-on-backdrop="true" :title="$t('Agent Location')" size="xl" @hidden="showAgentOnMap = false">
      <agent-location :agent-id="showOnMapAgent" />
    </b-modal>
    <b-modal id="viewOrderInfoModal" v-model="showOrderInfo" :cancel-title="$t('Dismiss')" :no-close-on-backdrop="true" :title="$t('Order Details')" size="xl" @hidden="showOrderInfo = false">
      <order-detail :is-shop-view="true" :active-order-detail="activeOrderDetail" />
    </b-modal>
    <b-modal
      id="requestHistoryModal"
      v-model="serviceHistoryProps.viewHistoryModal"
      :no-close-on-backdrop="true"
      :title="$t('Request History')"
      :hide-footer="true"
      @hidden="serviceHistoryProps.viewHistoryModal = false"
    >
      <app-timeline>
        <app-timeline-item
          v-for="(item, index) of serviceHistoryProps.requestHistoryList"
          :key="index"
          :title="getFormattedTimeline(item)"
          :variant="index == serviceHistoryProps.requestHistoryList.length - 1 ? 'secondary' : 'primary'"
        />
      </app-timeline>
    </b-modal>
    <div v-if="!isEmpty(orderDetails)" id="invoice-POS-main" class="d-none">
      <div style="max-width:400px;margin:0px auto">
        <div class="info">
          <div style="display: block; width: 100%; height: 60px">
            <div v-if="!isEmpty(orderDetails.store.picture)" style="float: left; width: 25%; text-align: left">
              <img :src="`${$apiUrl}${orderDetails.store.picture}`" style="" alt width="60" height="60">
            </div>
            <div style="float: left; width: 50%; text-align: center">
              <div style="font-size: 12px">Powered By</div>
              <div>
                <img :src="`${$apiUrl}/images/common/mom_logo.png`" alt="" style="max-width: 100%">
              </div>
            </div>
            <div v-if="!isEmpty(orderDetails.store.qrcode_url)" style="float: right; width: 25%; text-align: right">
              <img :src="`${$apiUrl}${orderDetails.store.qrcode_url}`" style="" alt width="60" height="60">
            </div>
          </div>
          <div style="display: block; width: 100%; height: 1px"></div>
          <div style="display: block; width: 100%">
            <p>
              <span>{{$t('Order ID')}} : {{orderDetails.store_order_invoice_id}} <br></span>
              <span>{{$t('Date')}} : {{dateTimeFormat(orderDetails.created_at, "YYYY/MM/DD")}} <br></span>
              <span>{{$t('Store Name')}} : {{orderDetails.store.store_name}} <br></span>
              <span>{{$t('Store Adress')}} : {{orderDetails.store.store_location}} <br></span>
              <span>{{$t('Store Email')}} : {{orderDetails.store.email}} <br></span>
              <span>{{$t('Store Phone')}} : {{orderDetails.store.contact_number}} <br></span>
              <span>{{$t('Customer')}} : {{orderDetails.user.first_name}} {{orderDetails.user.last_name}} <br></span>
            </p>
          </div>
        </div>

        <table class="table_data">
          <tbody>
            <tr v-for="item in orderDetails.invoice.items" :key="`${item.id}`">
              <td colspan="3">
                  {{item.product.item_name}}
                  <br>
                  <span>{{item.quantity}} {{ item.product && item.product.unit_value ? item.product.unit_value : '' }} {{ item.product && item.product.main_unit ? item.product.main_unit.name : '' }} X {{ orderDetails.currency }} {{ item.item_price }}</span>
                  <ul
                    v-if="getVariations(item).length > 0"
                    class="mb-0 pl-0"
                  >
                    <li
                      v-for="(addon, count) of getVariations(item)"
                      :key="count"
                    >
                      {{ addon.label }}: {{ addon.value }} 
                      <template v-if="!isEmpty(addon.price)">
                        ({{ orderDetails.currency }} {{ addon.price }})
                      </template>
                    </li>
                  </ul>
              </td>
              
              <td
                style="text-align:right;vertical-align:bottom"
              >
                {{ orderDetails.currency }} {{ item.item_price * item.quantity }}
              </td>
            </tr>

            <tr style="margin-top:10px" v-show="orderDetails.invoice">
              <td colspan="3" class="total">{{$t('Tax')}}</td>
              <td style="text-align:right;" class="total">{{ orderDetails.currency }} {{orderDetails.invoice.tax_amount}}</td>
            </tr>

            <tr style="margin-top:10px" v-show="orderDetails.invoice">
              <td colspan="3" class="total">{{$t('Discount')}}</td>
              <td style="text-align:right;" class="total">{{ orderDetails.currency }} {{orderDetails.invoice.discount}}</td>
            </tr>

            <tr style="margin-top:10px" v-show="orderDetails.invoice">
              <td colspan="3" class="total">{{$t('Shipping')}}</td>
              <td style="text-align:right;" class="total">{{ orderDetails.currency }} {{orderDetails.invoice.delivery_amount}}</td>
            </tr>

            <tr style="margin-top:10px" v-show="orderDetails.invoice">
              <td colspan="3" class="total">{{$t('Packaging Charge')}}</td>
              <td style="text-align:right;" class="total">{{ orderDetails.currency }} {{orderDetails.invoice.store_package_amount}}</td>
            </tr>

            <tr style="margin-top:10px">
              <td colspan="3" class="total">{{$t('Total')}}</td>
              <td
                style="text-align:right;"
                class="total"
              >
                {{orderDetails.invoice.total_amount}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */

import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import vSelect from 'vue-select'
import eventBus from '@/libs/event-bus'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import { useStoreUi } from './useStore'
import DashboardCards from './shared/DashboardCards.vue'
import OrderCreation from './shared/OrderCreation.vue'
import { useProviderUi } from '../provider/useProvider'
import FindAgents from './FindAgents.vue'
import OrderDetail from './shared/OrderDetail.vue'
import AgentLocation from '../shared/AgentLocation.vue'
import { isEmpty, getStatusBadgeColor, dateTimeFormat } from '@/utilities'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    GoodDataTable,
    DashboardCards,
    OrderCreation,
    vSelect,
    FindAgents,
    OrderDetail,
    AgentLocation,
  },
  data() {
    return {
      storeUrl: `${window.location.origin}/user/store/details/${window.SuperApp.getters.userInfo().slug}`,
      qrCodeUrl: `${jwtDefaultConfig.baseEndpoint}${window.SuperApp.getters.userInfo().qrcode_url}`,
      qrCodePage: `${window.location.origin}/user/shop/${window.SuperApp.getters.userInfo().slug}/${window.SuperApp.getters.userInfo().id}`,
      showAssignOrderToAgent: false,
      activeAssignOrder: '',
      statusList: ['ORDERED'],
      showAgentOnMap: false,
      showOnMapAgent: 0,
      showOrderInfo: false,
      activeOrderDetail: null,
      showCreateNewOrder: false,
      dispatcherList: [],
      accpetedList: [],
      showCancelOrderModel: false,
      storeCancelReason: '',
      orderAction: {},
      orderDetails: {},
      requestStatusList: [
        {
          label: 'Select',
          value: '',
        },
        {
          label: 'Accepted',
          value: 'ACCEPTED',
        },
        {
          label: 'Assigned',
          value: 'ASSIGNED',
        },
        {
          label: 'Pickup',
          value: 'PICKEDUP',
        },
        {
          label: 'Completed',
          value: 'COMPLETED',
        },
        {
          label: 'Cancelled',
          value: 'CANCELLED',
        },
      ],
      digitalOrderStatusList: [
        {
          label: 'Select',
          value: '',
        },
        {
          label: 'Completed',
          value: 'COMPLETED',
        },
        {
          label: 'Cancelled',
          value: 'CANCELLED',
        },
      ],
      statusImages: {
        DELIVERY: require('@/assets/images/app-icons/agent.png'),
        WALLET: require('@/assets/images/app-icons/wallet.png'),
        'DINE IN': require('@/assets/images/app-icons/shop.png'),
        DINE_IN: require('@/assets/images/app-icons/shop.png'),
        POS: require('@/assets/images/app-icons/POS.jpeg'),
        TAKEAWAY: require('@/assets/images/app-icons/take-away.png'),
        CASH: this.$helpers.getIcons('cash'),
        CARD: this.$helpers.getIcons('card'),
        'CARD ON DELIVERY': this.$helpers.getIcons('card-on-delivery'),
        CARD_ON_DELIVERY: this.$helpers.getIcons('card-on-delivery'),
        on_delivery: this.$helpers.getIcons('card-on-delivery'),
      },
      agentListProvider: [],
      serviceHistoryProps: {
        currentServiceForDispute: {},
        viewUpdateRequestModal: false,
        viewHistoryModal: false,
        requestHistoryList: [],
        updateRequest: {
          selectedStatus: '',
          notes: '',
          scheduleDate: '',
          assignedAgent: '',
          beforePickup: null,
          otp: null,
        },
      },
    }
  },
  computed: {
    accpetedOrdersColumns() {
      return [
        {
          label: 'Order ID',
          field: 'store_order_invoice_id',
        },
        {
          label: 'Created On',
          field: 'created_time',
          thClass: 'hide-on-mobile text-center',
          tdClass: 'hide-on-mobile text-center',
        },
        {
          label: 'Customer',
          field: 'first_name',
          resolvePath: 'user.first_name',
          thClass: 'hide-on-mobile text-center',
          tdClass: 'hide-on-mobile text-center',
          useRenderer: true,
          useResolver: true,
          useHtml: true,
          excelHtml: props => {
            if (props.user) {
              return `${props.user.first_name} ${props.user.last_name}`
            }
            return ''
          },
          renderer: props => {
            if (props.user) {
              return `<div class="d-flex align-items-center justify-content-center">
                        <img src="${window.SuperApp.helper.image(props.user.picture)}" onerror="this.src = '${
                window.SuperApp.constants.noImageAvailable
              }'" style="width: 40px; height: 40px; border-radius: 10%;" />
                        <div class="wrap-info">
                            <p class="mb-0 ml-1">${props.user.first_name} ${props.user.last_name}</p>
                            <p class="mb-0 ml-1">+${props.user.country_code ? props.user.country_code : ''}${props.user.mobile}</p>
                        </div>
                      </div>`
            }
            return ''
          },
        },
        // {
        //   label: 'Amount',
        //   field: 'invoice.total_amount',
        //   thClass: 'hide-on-mobile text-center',
        //   tdClass: 'hide-on-mobile text-center',
        //   useRenderer: true,
        //   useResolver: true,
        //   renderer: props => {
        //     if (props.invoice) {
        //       return `${props.invoice.total_amount} ${props.currency}`
        //     }
        //     if (props.order_type === 'DELIVERY' && props.without_item === 1) {
        //       return `${props.cod} ${props.currency}`
        //     }
        //     return ''
        //   },
        // },
        // {
        //   label: 'Order',
        //   field: 'invoice.items',
        //   resolvePath: 'invoice_items',
        //   useRenderer: true,
        //   useResolver: true,
        //   sortable: false,
        //   useHtml: true,
        //   excelHtml: props => {
        //     if (props.invoice) {
        //       return props.invoice.items.map(x => `Product: ${x.product.item_name} Quantity: ${x.quantity}`).join('')
        //     }
        //     return ''
        //   },
        //   renderer: props => {
        //     if (props.invoice) {
        //       return `<div class="d-block">
        //                 ${props.invoice.items.map(x => `<p class="mb-0 ml-1">${x.quantity} ${x.product.item_name}</p>`).join('')}
        //               </div>`
        //     }
        //     return ''
        //   },
        // },
        {
          label: 'Type',
          field: 'order_type',
          rField: 'order_type_text',
          thClass: 'hide-on-mobile text-center',
          tdClass: 'hide-on-mobile text-center',
          useResolver: true,
          useHtml: true,
          textRenderer: props => window.SuperApp.actions.replaceUnderscore(props.order_type),
          renderer: props => {
            if (props.order_type == 'DIGITAL') {
              return `<div class="d-flex align-items-center justify-content-center text-center wrap-dashboard-card-options width-fc custom-icons">
                  <img src="${this.$apiUrl}/images/icons/digital.png" />
                  <p class="mb-0 font-12">${window.SuperApp.actions.replaceUnderscore(props.order_type)}</p>
              </div>`
            } else {
              return `<div class="d-flex align-items-center justify-content-center text-center wrap-dashboard-card-options width-fc custom-icons">
                  <img src="${this.statusImages[props.order_type]}" onerror="this.src = '${window.SuperApp.constants.noImageAvailable}'" />
                  <p class="mb-0 font-12">${window.SuperApp.actions.replaceUnderscore(props.order_type)}</p>
              </div>`
            }
          },
        },
        {
          label: 'Status',
          field: 'status',
          rField: 'status',
          useResolver: true,
          useHtml: true,
          textRenderer: props => props.status,
          renderer: props => {
            const className = getStatusBadgeColor(props.status)
            const statusName = props.status.replaceAll('_', ' ')
            return `<span class="badge badge-pill badge-${className}">${statusName}</span>`
          },
        },
        {
          label: 'Agent',
          field: 'agent_name',
          resolvePath: 'agent.first_name',
          thClass: 'hide-on-mobile text-center',
          tdClass: 'hide-on-mobile text-center',
          useRenderer: true,
          useResolver: true,
          useHtml: true,
          excelHtml: props => {
            if (props.agent) {
              return `${props.agent.first_name} ${props.agent.last_name}`
            }
            if (props.invoice && props.invoice.items && props.invoice.items[0] && props.invoice.items[0].courier) {
              return props.invoice.items[0].courier.courier_name
            }
            return ''
          },
          renderer: props => {
            if (props.agent) {
              return `<div class="d-flex align-items-center justify-content-center">
                        <img src="${window.SuperApp.helper.image(props.agent.picture)}" onerror="this.src = '${
                window.SuperApp.constants.noImageAvailable
              }'" style="width: 40px; height: 40px; border-radius: 10%;" />
                        <div class="wrap-info">
                            <p class="mb-0 ml-1">${props.agent.first_name} ${props.agent.last_name}</p>
                            <p class="mb-0 ml-1">${props.agent.mobile}</p>
                        </div>
                      </div>`
            }
            if (props.invoice && props.invoice.items && props.invoice.items[0] && props.invoice.items[0].courier) {
              return props.invoice.items[0].courier.courier_name
            }
            return ''
          },
        },
        {
          label: 'Payment',
          field: 'payment_mode',
          resolvePath: 'invoice.payment_mode',
          thClass: 'hide-on-mobile text-center',
          tdClass: 'hide-on-mobile text-center',
          rField: 'payment_mode_text',
          useResolver: true,
          useRenderer: true,
          useHtml: true,
          excelHtml: props => {
            if (props.user) {
              return props.payment_mode
            }
            return ''
          },
          renderer: props => {
            if (props.user) {
              return `<img style="width: 30px; height: 30px;" src="${this.statusImages[props.payment_mode]}" onerror="this.src = '${window.SuperApp.constants.noImageAvailable}'" />`
            }
            return ''
          },
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          buttons: [
            {
              icon: 'track',
              title: 'Track Order',
              image: `${this.$apiUrl}/images/icons/track2.svg`,
              canRender: props => isEmpty(props.digital_files),
              action: props => {
                this.$router.push(`/shop/order/agent/view/${props.id}`)
              },
            },
            {
              icon: 'assignment',
              title: 'Assign Order',
              image: `${this.$apiUrl}/images/icons/assign.svg`,
              canRender: props => isEmpty(props.digital_files),
              action: props => {
                this.showAssignOrderToAgent = true
                this.activeAssignOrder = props.id
                // this.$router.push(`/shop/order/find-agent/${props.id}`)
              },
            },
            {
              icon: 'update',
              title: 'Update Order',
              image: `${this.$apiUrl}/images/icons/update.svg`,
              action: props => {
                this.viewUpdateRequestModal(props)
              },
            },
            {
              icon: 'View',
              title: 'View Order',
              image: `${this.$apiUrl}/images/icons/info.svg`,
              action: props => {
                this.viewServiceHistory(props)
              },
            },
            // {
            //   icon: 'request-history',
            //   title: 'Request History',
            //   image: require('@/assets/images/app-icons/1history.png'),
            //   action: props => {
            //     this.serviceHistoryProps.currentServiceForDispute = props
            //     this.getRequestHistory()
            //   },
            // },
            {
              icon: 'download-invoice',
              title: 'Download Invoice',
              image: `${this.$apiUrl}/images/icons/invoice.svg`,
              action: props => {
                this.$swal({
                  showDenyButton: true,
                  confirmButtonText: this.$t('Invoice'),
                  denyButtonText: this.$t('Receipt'),
                  customClass: {
                    confirmButton: 'btn btn-success',
                    denyButton: 'btn btn-primary ml-1',
                    icon: 'border-0',
                  },
                  buttonsStyling: false,
                }).then(result => {
                  if(result.isConfirmed){
                    if (props.invoice && props.invoice.invoice_url) {
                      window.open(window.SuperApp.helper.image(props.invoice.invoice_url))
                    }
                  }else if(result.isDenied){
                    this.printShopPdf(props)
                  }
                })
              },
            },
          ],
        },
      ]
    },
  },
  mounted() {
    this.getAgents()
    this.getOrdersBar()
    eventBus.$emit('reset-order-store-notification')
    eventBus.$on('refresh-store-accept-bars', this.getOrdersBar)
    eventBus.$on('refresh-store-accept-grid', this.refreshGridArea)
  },
  destroyed() {
    eventBus.$off('refresh-store-accept-bars', this.getOrdersBar)
    eventBus.$off('refresh-store-accept-grid', this.refreshGridArea)
  },
  methods: {
    isEmpty, dateTimeFormat,
    generateQr() {
      const { generateQr } = useStoreUi()
      showLoader()
      const formData = new FormData()
      formData.append('store_id', window.SuperApp.getters.userInfo().id)
      generateQr(formData)
        .then(({ data }) => {
          hideLoader()
          if (data.statusCode === '200') {
            window.open(this.qrCodePage)
          } else {
            showDangerNotification(this, 'Failed to generate QR')
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getFiltered($event) {
      let status = ''
      if ($event && $event.target && $event.target.value) {
        status = $event.target.value
      }
      this.$refs.myRequestsGrid.loadItems(status ? `status=${status}` : '')
    },
    getVariations(item) {
      const {product_data, cartaddon} = item
      if (!product_data) return []
      try {
        const addons = Object.entries(JSON.parse(product_data))
        const addonsMapped = addons.map(i => {
          const priceObj = cartaddon ? cartaddon.find(j => j.addon_name.toLowerCase() == i[0]) : {}
          return {
            label: i[0],
            value: i[1],
            price: !isEmpty(priceObj) ? priceObj.addon_price : null
          }
        });
        return addonsMapped
      } catch (error) {
        return []
      }
    },
    copyUrl() {
      if (window.SuperApp.helper.copyUrl(this.storeUrl)) {
        showSuccessNotification(this, 'Link copied successfully')
      } else {
        showDangerNotification(this, 'Failed to copy link')
      }
    },
    showOnMap(props) {
      this.showAgentOnMap = true
      this.showOnMapAgent = props.id
    },
    getFormattedTimeline(item) {
      return `${item.status} at ${item.created_at} ${item.agent ? ` by ${item.agent.first_name} ${item.agent.last_name}` : ''}`
    },
    refreshGridArea() {
      this.$refs.myRequestsGrid.loadItems()
    },
    getOrdersBar() {
      const { shopOrders } = useStoreUi()

      shopOrders()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.accpetedList = data.responseData.data
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getRequestHistory() {
      showLoader()
      this.serviceHistoryProps.requestHistoryList = []
      const { storeRequestHistoryList } = useStoreUi()
      storeRequestHistoryList(this.serviceHistoryProps.currentServiceForDispute.id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.serviceHistoryProps.viewHistoryModal = true
            this.serviceHistoryProps.requestHistoryList = data.responseData
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getAgents() {
      const { shopAgentList } = useStoreUi()

      shopAgentList()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.agentListProvider = data.responseData.data.map(x => ({
              label: x.name,
              value: x.id,
            }))
          } else {
            showDangerNotification(this, data.message)
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    inputFile(payload) {
      this.serviceHistoryProps.updateRequest.beforePickup = payload
    },
    viewUpdateRequestModal(props) {
      this.serviceHistoryProps.currentServiceForDispute = props
      this.serviceHistoryProps.viewUpdateRequestModal = true
    },
    assignOrder(payload) {
      const { shopAssignAgentToOrder } = useStoreUi()
      const formData = new FormData()

      formData.append('id', payload.id)
      formData.append('admin_service', 'ORDER')
      formData.append('force_assign', payload.force_assign ?? 0)
      formData.append('agent_id', this.serviceHistoryProps.updateRequest.assignedAgent)
      formData.append('comments', '')

      showLoader()
      shopAssignAgentToOrder(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, data.message)
            this.$refs.myRequestsGrid.loadItems()
            eventBus.$emit('refresh-on-going-services-socket', {
              userId: this.serviceHistoryProps.currentServiceForDispute.user_id,
              orderId: payload.id,
              service: 'ORDER',
            })
            eventBus.$emit('provider-sync-app', {
              agentId: this.serviceHistoryProps.updateRequest.assignedAgent,
              showIncomingRequest: true,
            })
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    updateRequestStatus() {
      if (this.serviceHistoryProps.updateRequest.selectedStatus === 'ASSIGNED') {
        this.assignOrder({...this.serviceHistoryProps.currentServiceForDispute, force_assign: 1})
        return
      }
      const formData = new FormData()

      const updateRequestDTO = this.serviceHistoryProps.currentServiceForDispute
      formData.append('id', updateRequestDTO.id)
      formData.append('status', this.serviceHistoryProps.updateRequest.selectedStatus)
      const assignedStatuses = [
        'ASSIGNED', 'REACHED', 'PICKEDUP', 'ARRIVED', 'DELIVERED', 'COMPLETED', 'OUT_FOR_DELIVERY', 'STARTED'
      ];
      if (assignedStatuses.includes(this.serviceHistoryProps.updateRequest.selectedStatus) && !isEmpty(this.serviceHistoryProps.updateRequest.assignedAgent)) {
        formData.append('agent_id', this.serviceHistoryProps.updateRequest.assignedAgent)
      }
      if (this.serviceHistoryProps.updateRequest.selectedStatus === 'DROPPED' && updateRequestDTO.delivery) {
        formData.append('latitude', updateRequestDTO.delivery.latitude)
        formData.append('longitude', updateRequestDTO.delivery.longitude)
      }
      showLoader()
      const { updateProviderRequest } = useProviderUi()
      updateProviderRequest({
        query: 'shop/update/order',
        data: formData,
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, 'Request updated successfully')
            this.$refs.myRequestsGrid.loadItems()
            eventBus.$emit('refresh-on-going-services-socket', {
              userId: this.serviceHistoryProps.currentServiceForDispute.user_id,
              orderId: updateRequestDTO.id,
              service: 'ORDER',
            })
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    print_pos() {
      var divContents = document.getElementById('invoice-POS-main').innerHTML
      var a = window.open('', '', 'height=500, width=500')
      a.document.write('<link rel="stylesheet" href="/css/pos_print.css"><html>')
      a.document.write('<body >')
      a.document.write(divContents)
      a.document.write('</body></html>')
      a.document.close()

      setTimeout(() => {
        a.print()
      }, 300)
    },
    printShopPdf(payload) {
      const { printShopPdf } = useStoreUi()
      showLoader()
      printShopPdf(payload.id)
        .then(({ data }) => {
          hideLoader()
          if (data) {
            this.orderDetails = data.responseData
            setTimeout(() => {
              this.print_pos()
            }, 100)
          } else {
            showDangerNotification(this, 'Failed to download invoice')
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    orderCreated(payload) {
      if (payload.order_type == 'POS') {
        this.$swal({
          title: this.$t('You want to print this order?'),
          iconHtml: this.$helpers.swalIcon('alert.png'),
          showCancelButton: true,
          confirmButtonText: this.$t('Yes'),
          cancelButtonText: this.$t('No'),
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger ml-1',
            icon: 'border-0',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.printShopPdf(payload)
          }
        })
      }
      this.showCreateNewOrder = false
      if (this.statusList.includes(payload.status)) {
        this.accpetedList.unshift(payload)
      }
      if (this.$refs.orderForm) {
        this.$refs.orderForm.resetForm()
      }
    },
    createOrderModal() {
      this.showCreateNewOrder = true
    },
    // eslint-disable-next-line no-unused-vars
    accpetedResponse(payload) {
      // this.accpetedList = payload.responseData.filter(x => this.statusList.includes(x.status))
      // window.accpetedList = this.accpetedList
      // setTimeout(() => {
      //   this.$refs.myRequestsGrid.rows = this.$refs.myRequestsGrid.rows.filter(x => !this.statusList.includes(x.status))
      // }, 100)
    },
    orderedResponse(payload) {
      this.dispatcherList = payload.responseData
    },
    dispatcherInfo(type) {
      const { dispatcherInfo } = useStoreUi()

      dispatcherInfo(type)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            if (type === 'ORDERED') this.dispatcherList = data.responseData
            else this.accpetedList = data.responseData
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    viewServiceHistory(payload) {
      this.showOrderInfo = true
      this.activeOrderDetail = payload
    },
    updateRequestApproval(props) {
      if (props.status === 'CANCELLED') {
        this.$swal({
          title: this.$t('You want to cancel this order?'),
          iconHtml: this.$helpers.swalIcon('confirm.png'),
          showCancelButton: true,
          confirmButtonText: this.$t('Yes'),
          cancelButtonText: this.$t('No'),
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger ml-1',
            icon: 'border-0',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.storeCancelReason = ''
            this.showCancelOrderModel = true
          }
        })
      } else if (props.status === 'ACCEPTED') {
        this.$swal({
          title: this.$t('You want to accept this order?'),
          iconHtml: this.$helpers.swalIcon('confirm.png'),
          showCancelButton: true,
          confirmButtonText: this.$t('Yes'),
          cancelButtonText: this.$t('No'),
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger ml-1',
            icon: 'border-0',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.acceptStoreOrder()
          }
        })
      }
      this.orderAction = props.payload
    },
    cancelStoreOrder() {
      showLoader()
      const { storeCancelOrder } = useStoreUi()
      const formData = new FormData()
      formData.append('id', this.orderAction.id)
      formData.append('cancel_reason', this.storeCancelReason)
      formData.append('store_id', this.orderAction.store_id)
      formData.append('user_id', this.orderAction.user_id)
      storeCancelOrder(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, data.message)
            this.$refs.myRequestsGrid.loadItems()
          } else {
            showDangerNotification(this, data.message)
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    acceptStoreOrder() {
      showLoader()
      const { storeAcceptOrder } = useStoreUi()
      const formData = new FormData()
      formData.append('store_order_id', this.orderAction.id)
      formData.append('user_id', this.orderAction.user_id)
      storeAcceptOrder(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, data.message)
            this.$refs.myRequestsGrid.loadItems()
            this.getOrdersBar()
            eventBus.$emit('refresh-on-going-services-socket', {
              userId: this.orderAction.user_id,
              orderId: this.orderAction.id,
              service: 'ORDER',
            })
          } else {
            showDangerNotification(this, data.message)
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
.shop-dashboard-info .user-profile-info ul.nav.nav-pills {
  justify-content: center;
}

.shop-dashboard-info.card .card.shadow-card {
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
}

.shop-dashboard-info .card .card-header {
  padding: 0 !important;
}
</style>

<style scoped>
.shop-dashboard-info .wrap-request-items {
  width: 25%;
  display: inline-block;
  margin-right: 20px;
}

.shop-dashboard-info .grid-view {
  overflow: auto;
  white-space: nowrap;
  max-width: 100%;
  padding: 10px;
}
</style>

<style>
body .dashboard-store-icons {
  height: 38px;
}

@media only screen and (max-width: 1024px) {
  .shop-dashboard-info .wrap-request-items {
    width: 33.33% !important;
  }

  .shop-dashboard-info .wrap-request-items .ecommerce-card {
    overflow-x: auto;
  }
}

@media only screen and (max-width: 1023px) {
  .shop-dashboard-info .wrap-request-items {
    width: 50% !important;
  }

  .shop-dashboard-info .wrap-request-items .ecommerce-card {
    overflow-x: auto;
  }
}

@media only screen and (max-width: 767px) {
  .shop-dashboard-info .wrap-request-items {
    width: 100% !important;
  }

  body .dashboard-store-icons {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
    height: auto;
  }

  body .dashboard-store-icons .custom-icons {
    margin: 0 !important;
    padding: 5px !important;
  }

  .shop-dashboard-info .wrap-request-items .ecommerce-card {
    overflow-x: auto;
  }

  .shop-dashboard-info .wrap-request-items .ecommerce-card button {
    margin-right: 10px;
    margin-left: 10px;
  }

  .shop-dashboard-info > .card-body {
    padding-right: 8px;
    padding-left: 8px;
  }

  .btn-wrapper-create-order-dashboard .grid-main-action-button button {
    margin: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>

<style>
.wrap-dashboard-card-options.custom-icons {
  border: 1px solid #ddd;
  padding: 2px 5px;
  border-radius: 4px;
}

.wrap-dashboard-card-options.custom-icons img {
  height: 20px;
  margin-right: 5px;
}
.store-profile-3-icons {
  width: 22px;
}
@media only screen and (max-width: 767px) {
  .store-profile-3-icons {
    width: 16px;
  }
}
.px-custom-5px{
  padding-left: 5px;
  padding-right: 5px;
}
</style>
